import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import redirectDefaultRouter from '@/helpers/redirectDefaultRouter';
import redirectAdminClientOrStoreRouter from '@/helpers/redirectAdminClientOrStoreRouter';
import permission from '@/helpers/permission';
import { getToken } from '@/helpers/http';
import routes from '@/router/routes';
import { RoleConstants } from '@/constants/role';

Vue.use(VueRouter);

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

const checkIfRedirectLogin = (to) => {
  return to.meta.requiredAuth && !store.getters['auth/token'];
};

const checkIfRedirectDefault = (to) => {
  return (
    (to.name === 'Login' && localStorage.getItem('token')) ||
    to.name === 'Default'
  );
};

const dispatchParentEventDetail = async (to) => {
  if (
    permission.isGmoOrOffice()
    && to.params.subdomain
    && !to.name.includes("SettingEventParent")
    && !to.name.includes("SettingEventChildEdit")
    && (!to.name.includes("EventUser") || to.meta?.useOmise)
    && !to.name.includes("EventCurrency")
  ) {
    await store.dispatch('event/getParentEventDetail', to.params.subdomain);
  }
}

const dispatchChildEventDetail = async (to) => {
  const subdomain = to.params.subdomain || store.getters['common/subdomain'];
  if (
    permission.isGmoOrOffice()
    && to.params.directory
    && !to.name.includes("SettingEvent")
    && (
      !store.getters['event/childEventDetail'].directory
      || store.getters['event/childEventDetail'].subdomain !== subdomain
      || store.getters['event/childEventDetail'].directory !== to.params.directory
    )
  ) {
    await store.dispatch('event/getChildEventDetail', {
      subdomain: subdomain,
      directory: to.params.directory,
    });
  }
}

const checkIfRedirect404 = async (to) => {
  const token = getToken()
  if (token && to.name !== '500') {
    if (token !== store.getters['auth/token']) {
      store.commit('auth/SET_TOKEN', token);
      await store.dispatch('auth/getUserInfor');
      return true;
    }
    if (to.name !== '404' && !store.getters['auth/userRole']) {
      await store.dispatch('auth/getUserInfor');
    }
    if (to.name === 'PasswordDefault' || to.name === 'PasswordCode') {
      return true;
    }
    if (
      to.name === 'PasswordReset' &&
      !store.getters['auth/isNearExpiredPassword']
    ) {
      return true;
    }
    if (to.meta?.notClientChild) {
      return store.getters['auth/isClientChild'];
    }
    if (to.name === 'ShopQr' && permission.isGmoOrOffice() && !store.getters['shop/newShopId']) {
      return true;
    }
    if (to.name === 'AccountCompany' && permission.isGmo() && !store.getters['shop/newShopId']) {
      return true;
    }
    if ([RoleConstants.ROLE_GMO, RoleConstants.ROLE_GMO_OR_OFFICE].includes(to.meta?.roles) && store.getters['common/isOldEvent']) {
      return true;
    }
    if (to.name === 'EventUserDetail') {
      return !store.getters['auth/isClientChild'];
    }
    if (to.name === 'ShopCouponRegister' && !store.getters['shop/shopDetail']?.id && store.getters['common/isGmoOrOfficeLoginStore']) {
      await store.dispatch('shop/getShopDetail', {
        subdomain: store.getters['common/subdomain'],
        id: store.getters['shop/newShopId'],
      });
      return !store.getters['shop/validFlag'];
    }
    if (to.name === 'EventCouponRegister' && store.getters['auth/isClientChild']) return true;
    if (to.meta.roles) {
      const userChildRoles = store.getters['auth/userChildRoles'];
      const userRole = store.getters['auth/userRole'];
      if (!to.meta.roles.includes(userRole)) {
        return true;
      }
      if (
        permission.isOffice() &&
        to.meta.childRoles
      ) {
        return Array.isArray(to.meta.childRoles)
          ? !to.meta.childRoles.every(role => userChildRoles.includes(role))
          : !userChildRoles.includes(to.meta.childRoles);
      }
    }
    if (
      (to.meta?.shopMenu ||
      to.meta?.shopSetting ||
      to.meta?.useCpm ||
      (to.meta?.isGmoOrOfficeLoginStore && store.getters['common/isGmoOrOfficeLoginStore']))
    ) {
      await store.dispatch('event/getParentEventDetail', store.getters['auth/commonSubdomain']);
      const parentEventDetail = store.getters['event/parentEventDetail'];
      const isShopSettingInvalid = !parentEventDetail.shopSettings?.[to.meta?.shopSetting] && to.meta?.shopSetting;
      const isIssueCouponInValid = (to.meta?.shopMenu === 'coupon') && (!parentEventDetail?.shopMenus?.['coupon'] || !store.getters['event/isShowMenuCoupon']);
      const isShopMenuInValid = to.meta?.shopMenu && !parentEventDetail?.shopMenus?.[to.meta?.shopMenu];
      const isCpmInValid = (!store.getters['event/isUseCpmParentEvent'] || !store.getters['event/canUseCpmParentEvent']) && to.meta?.useCpm;
      const isAccountValid = store.getters['common/isGmoOrOfficeLoginStore'] && to.meta?.isGmoOrOfficeLoginStore;
      return (!store.getters['common/isGmoOrOfficeLoginStore'] && (isShopSettingInvalid || isShopMenuInValid || isIssueCouponInValid)) || isCpmInValid || isAccountValid;
    }
  }
  return false;
};

router.beforeEach(async (to, from, next) => {
  // check the case of processing 2 tabs simultaneously if is old event
  const isOldEvent = localStorage.getItem('SUBDOMAIN') !== 'management' && JSON.parse(localStorage.getItem('isOldEvent'));
  store.commit('common/SET_IS_OLD_EVENT', isOldEvent);
  if (isOldEvent) store.dispatch('shop/setNewShopId', localStorage.getItem('shopId'));
  // check the case of backing browser if is old event
  if (to.name === 'EventShop' && isOldEvent) {
    store.commit('common/SET_IS_OLD_EVENT', false);
    localStorage.setItem('SUBDOMAIN', 'management');
    localStorage.removeItem('shopId');
  }
  if (to.name === 'EventUserEdit' && from.name?.includes('EventHistory')) {
    sessionStorage.setItem('historyPage', from.name);
    sessionStorage.setItem('historyDirectory', from.params?.directory);
  }
  if (to.query?.callback) {
    redirectAdminClientOrStoreRouter(to);
    await store.dispatch('auth/refreshToken');
    next({ path: to.path, replace: true, query: { back: true } });
  }
  if (store.getters['common/subdomain'] && store.getters['common/subdomain'] !== "management" && (to.name !== '404' && to.name !== '500')) {
    await store.dispatch('event/checkEvent');
  }
  if (to.params?.subdomain && to.name?.includes('EventCurrency') && !store.getters['event/parentEventDetail']?.id) {
    await store.dispatch('event/getParentEventDetail', to.params.subdomain);
  }
  if (checkIfRedirectLogin(to)) {
    next({ name: 'Login', params: { isOutOfSession: true } });
  } else if (await checkIfRedirect404(to) && to.name !== '404') {
    next({ name: '404' });
  } else if (checkIfRedirectDefault(to)) {
    redirectDefaultRouter(next);
  } else {
    next();
  }
});

const title = `${process.env.VUE_APP_APP_NAME}管理画面`;
router.afterEach((to,from) => {
  document.title = to.meta.title + ' | ' + title || title;
  if ((
    to.params.subdomain
    || to.name === 'SettingUser'
    || to.name === 'Notification'
    || to.name === 'NotificationRegister'
  ) && from.name && permission.isOffice()) {
    store.dispatch('auth/getUserInfor');
  }

  if (
    to.name === 'AccountUser' ||
    to.name === 'AccountCompany' ||
    to.name === 'AccountUserCpm' ||
    to.name === 'AccountUserLog' ||
    to.name === 'AccountUserCpmRegister' ||
    to.name === 'AccountUserCpmEdit'
  ) {
    store.dispatch('auth/getUserInfor');
  }
  Promise.all([
    dispatchParentEventDetail(to),
    dispatchChildEventDetail(to),
  ]).finally(() => {
    if ((to.name === 'EventHistoryBuy' || to.name === 'EventHistoryUse') && store.getters['auth/isClientChild'] && !store.getters['event/hasCurrency'] && !store.getters['event/hasHistoryTicketCompany'] && store.getters['event/useTicketFlag']
      || (to.meta?.useOmise && (!store.getters['event/useOmise'] || store.getters['auth/isClientChild']))) {
      router.push({ name: '404' });
    }
  })

  if (permission.isStoreOrGroup()) {
    const infor = store.getters['auth/infor'];
    store.dispatch('event/getParentEventDetail', infor.event?.subdomain);
  }
  if (store.getters['common/isGmoOrOfficeLoginStore']) {
    store.dispatch('event/getParentEventDetail', store.getters['common/subdomain']);
    store.dispatch('auth/getUserInfor');
    if (to.name !== 'ShopCouponRegister') {
      store.dispatch('shop/getShopDetail', {
        subdomain: store.getters['common/subdomain'],
        id: store.getters['shop/newShopId'] || Number(localStorage.getItem('shopId')),
      });
    }
  }
  if (
    permission.isStoreOrGroup() && to.params.directory && (
      !store.getters['event/childEventDetail'].directory
      || store.getters['event/childEventDetail'].directory !== to.params.directory
    )
  ) {
    store.dispatch('event/getChildEventDetail', {
      subdomain: store.getters['auth/infor'].event?.subdomain,
      directory: to.params.directory,
    });
  }
});

export default router;
